<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- user list -->
            <section class="published-jobs section-gap-30">
                <!-- Job Search -->
                <JobSearch @searched="searchJobs"></JobSearch>

                <!--Page Title-->
                <div class="section-title-wrap">
                    <h2 class="section-title text-capitalize">{{ $t(`published jobs`) }}</h2>
                </div>

                <div class="job-sections-wowax" v-if="isContentLoading">

                    <h4 class="empty-message" v-if="isEmpty">{{ $t(`No Published Jobs Found`) }}</h4>

                    <div v-else class="row row-cols-1 row-cols-lg-2">
                        <div class="col" v-for="job in publishedJobs">
                            <JobCard
                                    :job="job"
                                    :options="options"
                                    @jobDeleted="jobDeleted"
                                    @jobPublished="jobPublished"
                            ></JobCard>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <infinite-loading v-if="totalJob > 6" :identifier="infiniteKey" spinner="waveDots" @infinite="loadJobPosts">
                                <div slot="no-more"></div>
                                <div slot="no-results"></div>
                            </infinite-loading>
                        </div>
                    </div>

                </div>

                <div class="job-sections-wowax" v-else>
                    <div class="row row-cols-1 row-cols-lg-2">
                        <div class="col">
                            <JobCardLoader></JobCardLoader>
                        </div>
                        <div class="col">
                            <JobCardLoader></JobCardLoader>
                        </div>
                        <div class="col">
                            <JobCardLoader></JobCardLoader>
                        </div>
                        <div class="col">
                            <JobCardLoader></JobCardLoader>
                        </div>
                    </div>
                </div>

            </section>
            <modal-job-share
                    :job="shareJob"
                    v-if="showShareModal"
                    @closeModal="hideShareModal"
            ></modal-job-share>
        </div>
    </dashboard-layout>
</template>

<script>

import InfiniteLoading from "vue-infinite-loading";

const DashboardLayout = () => import("../../layouts/DashboardLayout.vue");
const JobSearch = () => import("../../components/jobs/JobSearch.vue");
const JobCard = () => import("../../components/jobs/JobCard.vue");
const Pagination = () => import("../../components/common/Pagination.vue");
const JobCardLoader = () => import("../../components/_loaders/JobCardLoader.vue");
const ModalJobShare = () => import("../../components/modal/job/Share.vue");

import {EventBus} from "../../extra/event-bus";
import {OPEN_JOB_SHARE_MODAL} from "../../constants/events";
import client from '../../app/api/Client';


export default {
    components: {
        JobCardLoader,
        DashboardLayout,
        JobSearch,
        JobCard,
        Pagination,
        ModalJobShare,
        InfiniteLoading
    },
    data() {
        return {
            isContentLoading: false,
            publishedJobs: [],
            options: {
                pipeline: true,
                candidates: true,
                delete: false,
                edit: false,
                share: true,
                duplicate: false,
                more: {
                    duplicate: true,
                    edit: true,
                    view: true,
                    delete: true,
                },
            },
            shareJob: null,
            showShareModal: false,
            page: 2,
            lastPage: null,
            infiniteKey: new Date(),
            totalJob: null,
            form: {
                search: ''
            },
        }
    },
    computed: {
        isEmpty() {
            return this.publishedJobs.length === 0;
        }
    },

    methods: {
        async jobPublished() {
            this.loadPublishedJobs();
        },
        async loadPublishedJobs() {
            this.isContentLoading = false;

            try {
                let {data: {data}} = await client().get('/job/published');

                this.publishedJobs = data.data;
                this.totalJob = data.total;
                this.lastPage = data.last_page;
            } catch (e) {

            }

            this.isContentLoading = true;
        },

        async loadJobPosts($state) {
            try {
                let queryParams = {params: {page: this.page, ...this.form}};

                let {data: {data}} = await client().get('/job/published', queryParams);
                this.page = data.current_page+1;
                this.lastPage = data.last_page;

                this.publishedJobs.push(...data.data);
            } catch (e) {

            }

            $state.loaded();
            if (this.page > this.lastPage) {
                $state.complete();
            }
        },

        async jobDeleted(job) {
            this.publishedJobs = this.publishedJobs.filter(pj => pj.id !== job.id);
        },

        async searchJobs(form) {
            this.isContentLoading = false;
            try {
                let queryParams = {params: {page: 1, ...form}};

                let {data: {data}} = await client().get('/job/published', queryParams);
                this.page = data.current_page+1;
                this.lastPage = data.last_page;
                this.totalJob = data.total;
                this.infiniteKey = new Date();
                this.publishedJobs = data.data;

            } catch (e) {
                // catch block
            }
            this.isContentLoading = true;
        },

        openShareModal(job) {
            this.showShareModal = true;
            this.shareJob = job;
            const el = document.body;
            el.classList.add('modal-open');
        },

        hideShareModal() {
            this.showShareModal = false;
            this.shareJob = null;
            const el = document.body;
            el.classList.remove('modal-open');
        }
    },

    async created() {
        await this.loadPublishedJobs();

        await EventBus.$on(OPEN_JOB_SHARE_MODAL, (job) => {
            this.openShareModal(job);
        });
    },

    beforeDestroy() {
        EventBus.$off(OPEN_JOB_SHARE_MODAL);
    }
}
</script>

<style>
.dropdown-menu.show {
    z-index: 985 !important;
}
</style>
